export const recruitKvSwiper = () => {
    if (!document.querySelector('.recruitPage')) return;


    const swiper = new Swiper('.swiper-container', {
        loop: true,
        loopedSlides: 9,
        slidesPerView: 'auto',
        watchOverflow: true,
        spaceBetween: 0,
        speed: 5000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        direction: 'vertical',
        loopFillGroupWithBlank: true,
        centeredSlides: true,
        slidesPerView: 'auto',
    });

    const swiper02 = new Swiper('.swiper-container02', {
        loop: true,
        slidesPerView: 'auto',
        watchOverflow: true,
        spaceBetween: 0,
        speed: 5000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
            stopOnLastSlide: false,  // Ensure it continues
        },
        direction: 'vertical',
        loopAdditionalSlides: 2,  // Add extra slides to help with continuous looping
        slidesPerView: 'auto',
        centeredSlides: true,
    });

}