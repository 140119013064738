export const contactSticky = () => {
    let scrollTimeout;

    function checkScroll() {
        if (document.querySelector('.formSec')) {
            return;
        }

        const svh = window.innerHeight / 100;
        const contactSticky = document.querySelector('.contactSticky');
        const footer = document.querySelector('.footer');
        const sun = document.querySelector('.sun');
        const serviceArea = document.querySelector('.serviceArea__container');

        // Check if elements exist before proceeding
        if (!contactSticky || !footer) {
            console.warn('Required elements not found');
            return;
        }

        // First check if we're past the initial viewport height
        if (window.scrollY > window.innerHeight) {
            const footerRect = footer.getBoundingClientRect();
            const footerOffset = footerRect.top - window.innerHeight;

            if (serviceArea) {
                const serviceAreaRect = serviceArea.getBoundingClientRect();

                // Check if either serviceArea or footer is in viewport
                if (
                    (serviceAreaRect.top < window.innerHeight && serviceAreaRect.bottom >= 0) ||
                    footerOffset <= 0
                ) {
                    contactSticky.classList.remove('show');
                } else {
                    contactSticky.classList.add('show');
                }
            } else {
                // If no serviceArea, only check footer
                if (footerOffset <= 0) {
                    contactSticky.classList.remove('show');
                } else {
                    contactSticky.classList.add('show');
                }
            }
        } else {
            contactSticky.classList.remove('show');
        }

        // Handle sun animation
        if (sun) {
            sun.classList.add('visible');
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                sun.classList.remove('visible');
            }, 1000);
        }
    }

    // Add debouncing to prevent excessive calculations
    function debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    // Attach the debounced scroll listener
    window.addEventListener('scroll', debounce(checkScroll, 10));

    // Initial check
    checkScroll();
}