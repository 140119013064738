export const swiper3d = () => {
    if (!document.querySelector('.recruitPage')) return;

    // CMS Endpoints
    const API_ENDPOINTS = {
        // staffBlog: "http://cmsfg.cgsolutions.jp/wp-json/wp/v2/staff-blog",
        staffBlog: "https://crm.fgd.jp/wp-json/wp/v2/staff-blog",
    };

    // Utility: Create an article element for each item
    function createBlogElement(item, index) {
        var publishingDate = new Date(item.date);
        var day = publishingDate.getDate();
        var month = publishingDate.getMonth() + 1;
        var year = publishingDate.getFullYear();

        var fullDate = year + '.' + month + '.' + day;

        $("#blog-container").append('<a href="/blog-details.html?id=' + item.id + '" class="slide' + (index == 0 ? ' active' : '') + '" onclick="window.location.href=\'/blog-details.html?id=' + item.id + '\'"><figure class="slide-figure"><img src="' + (item.eyecatch != null ? item.eyecatch : '../assets/images/top/newsSec/news-thumb.png') + '" alt="" /></figure><div class="slide-textContainer"><div class="slide-date">' + fullDate + '</div><div class="slide-text">' + item.title.rendered + '</div></div></a>');
    }

    // Function: Fetch and render data
    async function fetchAndRenderData(endpoint, containerId) {
        const container = document.getElementById(containerId);

        const response = await fetch(endpoint);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        // Populate container with articles or a "no content" message
        if (Array.isArray(data) && data.length > 0) {
            data.forEach((item, index) => {
                createBlogElement(item, index);
            });

            // Initialize slider after adding all slides
            initializeSlider();

            $("#down").text(data.length);
        } else {
            container.textContent = "No content available.";
        }
    }

    // Slider initialization function
    function initializeSlider() {
        const slides = document.querySelectorAll(".slide");
        const sliderContainer = document.querySelector(".slider-container");
        const counterDown = document.querySelector(".counter .down");
        const prevBtn = document.querySelector(".prev-btn");
        const nextBtn = document.querySelector(".next-btn");

        let currentIndex = 0;

        // Width constants
        const activeWidth = 56; // Active slide width (rem)
        const inactiveWidth = 32; // Inactive slide width (rem)
        const gap = 10; // Gap between slides (rem)

        // Swipe and drag detection variables
        let touchStartX = 0;
        let touchEndX = 0;
        const minSwipeDistance = 50; // Minimum distance for a swipe to be recognized

        // Mouse drag variables
        let isDragging = false;
        let startX;
        let scrollLeft;

        function updateSlider() {
            // Ensure slides are selected after they are dynamically added
            const slides = document.querySelectorAll(".slide");

            // Determine if the device is mobile
            const isMobile = window.innerWidth <= 768;

            // Adjust dimensions for mobile
            const currentGap = isMobile ? 1 : gap;
            const currentActiveWidth = isMobile ? 56 : activeWidth;
            const currentInactiveWidth = isMobile ? 56 : inactiveWidth;

            // Calculate offset: Align the active slide fully visible
            const offset = currentIndex * (currentInactiveWidth + currentGap);

            // Move the slider container
            sliderContainer.style.transform = `translateX(-${offset}rem)`;

            // Clear any existing active classes
            slides.forEach(slide => {
                slide.classList.remove("active");
                slide.style.width = `${currentInactiveWidth}rem`;
                slide.style.height = "31.6rem";
            });

            // Add active class to current slide
            if (slides[currentIndex]) {
                slides[currentIndex].classList.add("active");
                slides[currentIndex].style.width = `${currentActiveWidth}rem`;
                slides[currentIndex].style.height = "54.4rem";
            }

            // Update counter
            $("#up").text(currentIndex + 1);
        }

        function handleNext() {
            const totalSlides = $(".slide").length;
            currentIndex = (currentIndex + 1) % totalSlides;
            updateSlider();
        }

        function handlePrev() {
            const totalSlides = $(".slide").length;
            currentIndex = (currentIndex - 1 + totalSlides) % totalSlides;
            updateSlider();
        }

        // Touch event handlers
        function handleTouchStart(event) {
            touchStartX = event.touches[0].clientX;
        }

        function handleTouchMove(event) {
            touchEndX = event.touches[0].clientX;
        }

        function handleTouchEnd() {
            const touchDiff = touchStartX - touchEndX;

            // Determine if it's a swipe
            if (Math.abs(touchDiff) > minSwipeDistance) {
                if (touchDiff > 0) {
                    // Swipe left (next slide)
                    handleNext();
                } else {
                    // Swipe right (previous slide)
                    handlePrev();
                }
            }
        }

        // Mouse drag event handlers
        function handleMouseDown(e) {
            isDragging = true;
            startX = e.pageX - sliderContainer.offsetLeft;
            scrollLeft = sliderContainer.scrollLeft;
        }

        function handleMouseLeave() {
            isDragging = false;
        }

        function handleMouseUp(e) {
            if (!isDragging) return;
            isDragging = false;

            const x = e.pageX - sliderContainer.offsetLeft;
            const mouseDiff = x - startX;

            // Determine if it's a drag
            if (Math.abs(mouseDiff) > minSwipeDistance) {
                if (mouseDiff > 0) {
                    // Drag right (previous slide)
                    handlePrev();
                } else {
                    // Drag left (next slide)
                    handleNext();
                }
            }
        }

        function handleMouseMove(e) {
            if (!isDragging) return;
            e.preventDefault();
        }

        // Click event listeners for buttons
        prevBtn.addEventListener("click", handlePrev);
        nextBtn.addEventListener("click", handleNext);

        // Touch event listeners for swipe
        // sliderContainer.addEventListener("touchstart", handleTouchStart);
        // sliderContainer.addEventListener("touchmove", handleTouchMove);
        // sliderContainer.addEventListener("touchend", handleTouchEnd);

        // // Mouse event listeners for drag
        // sliderContainer.addEventListener("mousedown", handleMouseDown);
        // sliderContainer.addEventListener("mouseleave", handleMouseLeave);
        // sliderContainer.addEventListener("mouseup", handleMouseUp);
        // sliderContainer.addEventListener("mousemove", handleMouseMove);

        // Prevent default touch behaviors that might interfere with swiping
        // sliderContainer.addEventListener("touchstart", (e) => e.preventDefault(), { passive: false });
        // sliderContainer.addEventListener("touchmove", (e) => e.preventDefault(), { passive: false });

        // Prevent default drag behavior
        // sliderContainer.style.cursor = "grab";
        // sliderContainer.addEventListener("dragstart", (e) => e.preventDefault());

        // Add a listener for window resize to dynamically reapply settings
        window.addEventListener("resize", updateSlider);

        // Initialize the slider
        updateSlider();
    }

    // Fetch and render data for each section
    fetchAndRenderData(API_ENDPOINTS.staffBlog, "staff-blog");
};