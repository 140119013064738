export const burgerMenu = () => {
    const hamburgerContainer = document.querySelector('.header__hamburgerContainer');
    const hamburger = document.querySelector('.header__hamburger');
    const nav = document.querySelector('.header__nav');
    const mainLogo = document.querySelector('.header__mainLogo');
    const hamburgerText = document.querySelector('.header__hamburgerText');
    const btnInquiry = document.querySelector('.btn--inquiry');
    const header = document.querySelector('.header');
    const headerInner = document.querySelector('.header__inner');
    const headerLogoMenu = document.querySelector('.header__logo--menu');
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    const toggleMenu = () => {
        const isActive = hamburger.classList.contains('is-active');
        hamburger.classList.toggle('is-active', !isActive);
        nav.classList.toggle('is-active', !isActive);
        header.classList.toggle('is-active', !isActive); 
        headerInner.classList.toggle('is-active', !isActive); 
        headerLogoMenu.classList.toggle('is-active', !isActive); 
        hamburgerText.textContent = isActive ? 'MENU' : 'CLOSE';
        mainLogo.classList.toggle('is-active', !isActive);
        hamburgerText.classList.toggle('is-active', !isActive);   
        btnInquiry.classList.toggle('is-active', !isActive);    
        html.classList.toggle('is-active', !isActive);    
        body.classList.toggle('is-active', !isActive);   
        hamburgerContainer.classList.toggle('is-active', !isActive); 
    };

    hamburgerContainer.addEventListener('click', toggleMenu);
};
