export const partnerApi = () => {
    if (!document.querySelector('#partnerDetails')) return;
    // CMS Endpoints
    const API_ENDPOINTS = {
        // partnerDetails: "http://cmsfg.cgsolutions.jp/wp-json/wp/v2/news",
        partnerDetails: "https://crm.fgd.jp/wp-json/wp/v2/partner-blog",
    };


    // Function: Fetch and render data
    async function fetchAndRenderNewsDataDetails(endpoint) {

        const response = await fetch(endpoint);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        //console.log(data);

        var publishingDate = new Date(data.date);
        var date = publishingDate.toLocaleDateString();

        var day = publishingDate.getDate();
        var month = publishingDate.getMonth() + 1; //months are zero based
        var year = publishingDate.getFullYear();

        var fullDate = year + '.' + month + '.' + day;


        $(document).ready(function () {
            $("#partner_title").append(data.title.rendered);
            $("#partner_date").append(fullDate);
            $("#partner_container").append(data.content.rendered);

            if (data.eyecatch) {
                $('#partner_eyecatch').attr('src', data.eyecatch + '?rand=' + Math.random());
            } else {
                $('#partner_eyecatch').attr('src', ' ./assets/images/top/newsSec/news-thumb.png');
            }

        });
    }


    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');

        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    }


    // for news details page
    const query = getQueryParams(document.location.search);

    if (query.id) {
        fetchAndRenderNewsDataDetails(API_ENDPOINTS.partnerDetails + '/' + query.id);
    }


}