export const validation = () => {
    const form = document.getElementById("contactForm");
    const submitBtn = document.getElementById("submitBtn");

    if (!form || !submitBtn) return;

    function createErrorElement(message) {
        const errorDiv = document.createElement("div");
        errorDiv.className = "error-message";
        errorDiv.style.color = "#ff0000";
        errorDiv.style.fontSize = "14px";
        errorDiv.style.marginTop = "5px";
        errorDiv.textContent = message;
        return errorDiv;
    }

    function clearErrors() {
        document.querySelectorAll(".error-message").forEach(error => error.remove());
    }

    function validateForm() {
        clearErrors();
        let isValid = true;
        let firstErrorElement = null;

        // Validate select field
        const selectField = form.querySelector(".formSec__select");
        const selectContainer = selectField.closest(".formSec__selectContainer");
        if (!selectField.value || selectField.value === "") {
            const errorElement = createErrorElement("お問い合わせ種別を選択してください");
            selectContainer.insertAdjacentElement("afterend", errorElement);
            if (!firstErrorElement) firstErrorElement = errorElement;
            isValid = false;
        }

        // Validate required input fields (excluding company and position)
        const requiredInputs = form.querySelectorAll("input[type='text']:not([name='company']):not([name='position']), input[type='tel'], input[type='email']");
        requiredInputs.forEach(input => {
            if (!input.value.trim()) {
                const container = input.closest(".formSec__container");
                const label = container.querySelector(".formSec__label")?.textContent || "必須項目";
                const errorElement = createErrorElement(`${label}を入力してください`);
                container.appendChild(errorElement);
                if (!firstErrorElement) firstErrorElement = errorElement;
                isValid = false;
            }
        });

        // Scroll to the first error if validation fails
        if (!isValid && firstErrorElement) {
            firstErrorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }

        return isValid;
    }

    submitBtn.addEventListener("click", function (e) {
        e.preventDefault();

        if (validateForm()) {
            form.submit();
        }
    });
}