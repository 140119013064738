import { polyfill } from "./modules/_polyfill";
import { aos } from "./modules/_aos";
import { burgerMenu } from "./modules/_burgerMenu";
import { accordion } from "./modules/_accordion";
import { newsSlider } from "./modules/_newsSlider";
import { tabs } from "./modules/_tabs";
import { menuSlider } from "./modules/_menuSlider";
import { swiperSp } from "./modules/_swiperSp";
import { swiperPc } from "./modules/_swiperPc";
import { printingSwiper } from "./modules/_printingSwiper";
import { recruitTab } from "./modules/_recruitTab";
import { swiper3d } from "./modules/_swiper3d";
import { counter } from "./modules/_counter";
import { animateLine } from "./modules/_animateLine";
import { recruitKvSwiper } from "./modules/_recruitKvSwiper";
import { partnerApi } from "./modules/_partnerApi";
import { footerYear } from "./modules/_footerYear";
import { contactSticky } from "./modules/_contactSticky";
import { validation } from "./modules/_validation";
import { header } from "./modules/_header";
import { videoPlayer } from "./modules/_videoPlayer";

document.addEventListener('DOMContentLoaded', () => {
    polyfill();
    aos();
    burgerMenu();
    accordion();
    newsSlider();
    menuSlider();
    tabs();
    swiperSp();
    swiperPc();
    printingSwiper();
    recruitTab();
    swiper3d();
    counter();
    animateLine();
    recruitKvSwiper();
    partnerApi();
    footerYear();
    contactSticky();
    validation();
    header();
    videoPlayer();
});

