export const tabs = () => {
    const allTabs = document.querySelectorAll(".tab");

    allTabs.forEach((tabsContainer) => {
        const tabButtons = tabsContainer.querySelectorAll(".tab-button");
        const tabPanels = tabsContainer.querySelectorAll(".tab-panel");

        tabButtons.forEach((button) => {
            button.addEventListener("click", () => {
                // Remove active class from all buttons and panels within this tabs container
                tabButtons.forEach((btn) => btn.classList.remove("active"));
                tabPanels.forEach((panel) => panel.classList.remove("active"));

                // Add active class to the clicked button and corresponding panel
                button.classList.add("active");
                const targetPanel = tabsContainer.querySelector(`#${button.dataset.tab}`);
                targetPanel.classList.add("active");
            });
        });
    });
}