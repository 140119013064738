export const newsSlider = () => {
    let swiper;

    function initSwiper() {
        const swiperElement = document.querySelector('.newsSec__swiper');
    
        if (swiperElement && window.innerWidth < 769) {
            if (!swiper) {
                swiper = new Swiper(swiperElement, {
                    slidesPerView: 2,
                    navigation: {
                        nextEl: '.newsSec__next',
                        prevEl: '.newsSec__prev',
                    },
                });
            }
        } else if (swiper) {
            swiper.destroy(true, true); // Completely destroy the Swiper instance
            swiper = null;
        }
    }
    
    // Initialize Swiper on load
    initSwiper();
    
    // Reinitialize Swiper on window resize with debouncing
    let resizeTimeout;
    window.addEventListener('resize', () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(initSwiper, 300);
    });
    

}