export const printingSwiper = () => {

    const swiperContainer = document.querySelector('.swiper-tab-03');

    const swiperContainer02 = document.querySelector('.swiper-tab-04');

    if (swiperContainer) {
        const swiper = new Swiper('.swiper-tab-03', {
            slidesPerView: 1, // Default to 1 slide
            navigation: {
                nextEl: '.printingSec__next',
                prevEl: '.printingSec__prev',
            },
            pagination: {
                el: '.swiper-tab-03-pagination',
                clickable: true,
            },
    
            breakpoints: {
                769: {
                    slidesPerView: 'auto', // For 769px and up
                    slidesPerGroup: 3,
                },
            },
        });
    }

    if (swiperContainer02) {
        const swiper = new Swiper('.swiper-tab-04', {
            slidesPerView: 1, // Default to 1 slide
            navigation: {
                nextEl: '.printingSec__next',
                prevEl: '.printingSec__prev',
            },
            pagination: {
                el: '.swiper-tab-04-pagination',
                clickable: true,
            },
    
            breakpoints: {
                769: {
                    slidesPerView: 'auto', // For 769px and up
                    slidesPerGroup: 2,
                },
            },
        });
    }

    

    
};
