export const accordion = () => {
    const headerItems = document.querySelectorAll(".header__item");

headerItems.forEach((item) => {
    const accordionBtn = item.querySelector(".header__accordionBtn");
    const itemTitle = item.querySelector(".header__itemTitle");
    const subMenu = item.querySelector(".header__subMenu");
    const navContainer = document.querySelectorAll(".header__navContainer")[0];

    if (subMenu) {
        // Add class 'hasSubMenu' to accordionBtn
        accordionBtn.classList.add("hasSubMenu");

        // Add click event listener to header__itemTitle instead
        itemTitle.addEventListener("click", function () {
            const isExpanded = subMenu.style.maxHeight;

            // Close all open submenus
            document.querySelectorAll(".header__subMenu").forEach((menu) => {
                menu.style.maxHeight = null;
                menu.previousElementSibling.classList.remove("isOpen");
            });

            if (!isExpanded) {
                subMenu.style.maxHeight = subMenu.scrollHeight + "px";
                accordionBtn.classList.add("isOpen");
                navContainer.classList.add("isOpen");
            } else {
                subMenu.style.maxHeight = null;
                accordionBtn.classList.remove("isOpen");
                navContainer.classList.remove("isOpen");
            }
        });
    }
});


}