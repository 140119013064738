export const aos = () => {
    AOS.init({
        duration: 1000,
        useClassNames: true, // Enable the use of classNames
        initClassName: false, // Disable the default `aos-init` class
        animatedClassName: 'aos-animate', // Default animated class
    });

    // Function to update AOS animation based on screen width
    function updateAOSAnimation() {
        const serviceTitles = document.querySelectorAll('.serviceSec__title');

        if (!serviceTitles.length) return; // Guard clause if no elements exist

        serviceTitles.forEach(title => {
            if (window.innerWidth >= 769) {
                // Desktop animation
                title.setAttribute('data-aos', 'fade-right');
            } else {
                // Mobile animation
                title.setAttribute('data-aos', 'fade-blur');
            }
        });

        // Refresh AOS to apply the new animations
        if (typeof AOS !== 'undefined') {
            AOS.refresh();
        }
    }

    // Run on page load
    document.addEventListener('DOMContentLoaded', updateAOSAnimation);

    // Run on window resize
    window.addEventListener('resize', updateAOSAnimation);



    const items = document.querySelectorAll(".recruitSec__listItem");

    // Check if items exist
    if (items.length === 0) {
        return; // Exit the function if no items are found
    }

    const isDesktop = window.innerWidth >= 769; // Define breakpoint
    const baseDelay = isDesktop ? 200 : 100; // Delay increment based on device

    items.forEach((item, index) => {
        if (index >= 1) { // Apply delay only to 2nd, 3rd, and 4th items
            const delay = index * baseDelay;
            item.setAttribute("data-aos-delay", delay);
        }
    });
}