export const polyfill = () => {
  // Promise Polyfill
  if (!window.Promise) {
    window.Promise = function (executor) {
      let state = 'pending';
      let value = null;
      let handlers = [];

      function resolve(result) {
        if (state !== 'pending') return;
        state = 'fulfilled';
        value = result;
        handlers.forEach(handle);
        handlers = null;
      }

      function reject(error) {
        if (state !== 'pending') return;
        state = 'rejected';
        value = error;
        handlers.forEach(handle);
        handlers = null;
      }

      function handle(handler) {
        if (state === 'pending') {
          handlers.push(handler);
        } else {
          if (state === 'fulfilled' && typeof handler.onFulfilled === 'function') {
            handler.onFulfilled(value);
          }
          if (state === 'rejected' && typeof handler.onRejected === 'function') {
            handler.onRejected(value);
          }
        }
      }

      this.then = function (onFulfilled, onRejected) {
        return new Promise(function (resolve, reject) {
          handle({
            onFulfilled: function (result) {
              if (typeof onFulfilled === 'function') {
                try {
                  resolve(onFulfilled(result));
                } catch (err) {
                  reject(err);
                }
              } else {
                resolve(result);
              }
            },
            onRejected: function (error) {
              if (typeof onRejected === 'function') {
                try {
                  resolve(onRejected(error));
                } catch (err) {
                  reject(err);
                }
              } else {
                reject(error);
              }
            }
          });
        });
      };

      executor(resolve, reject);
    };
  }

  // Fetch Polyfill
  if (!window.fetch) {
    window.fetch = function (url, options) {
      return new Promise(function (resolve, reject) {
        var request = new XMLHttpRequest();
        request.open(options.method || 'GET', url);

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            resolve({
              json: function () {
                return Promise.resolve(JSON.parse(request.responseText));
              }
            });
          } else {
            reject(new Error(request.statusText));
          }
        };

        request.onerror = function () {
          reject(new Error("Network error"));
        };

        if (options.headers) {
          Object.keys(options.headers).forEach(function (key) {
            request.setRequestHeader(key, options.headers[key]);
          });
        }

        request.send(options.body || null);
      });
    };
  }

  // Object.assign Polyfill
  if (typeof Object.assign !== 'function') {
    Object.assign = function (target, varArgs) {
      if (target == null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) {
          for (var nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    };
  }

}