export const swiperPc = () => {
    let swiper;

    function initSwiper() {
        const swiperElement = document.querySelector('.swiper-tab-02');
    
        if (swiperElement && window.innerWidth > 769) {
            if (!swiper) {
                swiper = new Swiper(swiperElement, {
                    slidesPerView: 1,
                    pagination: {
                        el: '.swiper-tab-02-pagination',
                        clickable: true,
                    }
                });
            }
        } else if (swiper) {
            swiper.destroy(true, true); // Completely destroy the Swiper instance
            swiper = null;
        }
    }
    
    // Initialize Swiper on load
    initSwiper();
    
    // Reinitialize Swiper on window resize with debouncing
    let resizeTimeout;
    window.addEventListener('resize', () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(initSwiper, 300);
    });
    
}