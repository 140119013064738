export const menuSlider = () => {

    const swiperElements = document.querySelectorAll('.portfolioSec__swiper');

    swiperElements.forEach((swiperElement) => {
        const container = swiperElement.closest('.portfolioSec__swiperContainer'); // Get the container
        if (container) {
            const tab = container.closest('.tab-panel'); // Get the parent tab-panel
            if (tab && tab.id) {
                // Extract tab number
                const tabNumber = tab.id.replace('tab', ''); // Get "3" from "tab3"
                const paginationElement = container.querySelector('.portfolioSec__pagination');
                if (paginationElement) {
                    // Add dynamic pagination class
                    const dynamicClass = `portfolioSec__pagination${tabNumber}`;
                    paginationElement.classList.add(dynamicClass);

                    // Initialize Swiper
                    new Swiper(swiperElement, {
                        slidesPerView: 1, // Default slide view

                        pagination: {
                            el: `.${dynamicClass}`, // Use the dynamically added class
                            clickable: true,
                        },
                        breakpoints: {
                            769: {
                                slidesPerView: 1, // For screens 769px and larger
                            },
                        },
                    });
                }
            }
        }
    });


};