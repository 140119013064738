export const counter = () => {
    const counters = document.querySelectorAll('.companyStats__number');

    if (!counters.length) return;

    const animateCounter = (counter) => {
        const target = parseFloat(counter.getAttribute('data-target'));
        const duration = parseInt(counter.getAttribute('data-duration')) || 2000;
        
        // Determine if the original input has a decimal point
        const isDecimal = counter.getAttribute('data-target').includes('.');
        const precision = isDecimal ? (counter.getAttribute('data-target').split('.')[1].length) : 0;

        const start = 0;
        const increment = target / (duration / 16);

        let current = start;

        const updateCounter = () => {
            current += increment;
            if (current < target) {
                // If original input had a decimal, show decimals
                // Otherwise, show whole number
                counter.textContent = isDecimal 
                    ? current.toFixed(precision) 
                    : Math.floor(current);
                
                requestAnimationFrame(updateCounter);
            } else {
                // Final value handling
                counter.textContent = isDecimal 
                    ? target.toFixed(precision) 
                    : Math.floor(target);
            }
        };

        updateCounter();
    };

    const observerOptions = {
        threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                animateCounter(entry.target);
                observer.unobserve(entry.target);
            }
        });
    }, observerOptions);

    counters.forEach((counter) => {
        observer.observe(counter);
    });
};