export const animateLine = () => {
    // Function to handle the mutation changes
    function handleMutations(mutationsList, observer) {
        mutationsList.forEach(mutation => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const spanElement = mutation.target;
                // Find the sibling container (either `.animated-container` or `.animated-container02`)
                const animatedContainer = spanElement.nextElementSibling;

                if (animatedContainer) {
                    // Check if the container is `.animated-container` or `.animated-container02`
                    if (animatedContainer.classList.contains('animated-container')) {
                        const animatedLine = animatedContainer.querySelector('.animated-line');
                        if (animatedLine) {
                            // Toggle `is-active` based on `aos-animate`
                            animatedLine.classList.toggle('is-active', spanElement.classList.contains('aos-animate'));
                        }
                    } else if (animatedContainer.classList.contains('animated-container02')) {
                        const animatedLine02 = animatedContainer.querySelector('.animated-line02');
                        if (animatedLine02) {
                            // Toggle `is-active` based on `aos-animate`
                            animatedLine02.classList.toggle('is-active', spanElement.classList.contains('aos-animate'));
                        }
                    }
                }
            }
        });
    }

    // Create a MutationObserver to watch for changes in the span elements
    const observer = new MutationObserver(handleMutations);

    // Select all span elements with the `data-aos` attribute
    const spanElements = document.querySelectorAll('span[data-aos]');

    // Observe each span element for attribute changes
    spanElements.forEach(span => {
        observer.observe(span, {
            attributes: true // Configure it to listen to attribute changes
        });
    });
}